export class YoutubeUtils {
  static getYoutubeVideoId(url: string): string | null {
    try {
      const parsedUrl = new URL(url);
      const hostname = parsedUrl.hostname;
      const pathname = parsedUrl.pathname;
      const searchParams = parsedUrl.searchParams;

      if (hostname.includes("youtube.com")) {
        if (pathname === "/watch") {
          const videoId = searchParams.get("v");
          return videoId;
        } else if (pathname.startsWith("/embed/")) {
          const parts = pathname.split("/");
          if (parts.length > 2) {
            return parts[2];
          }
          return null;
        }
      }

      if (hostname === "youtu.be") {
        const parts = pathname.split("/");
        if (parts.length > 1 && parts[1]) {
          return parts[1];
        }
        return null;
      }

      return null;
    } catch (error) {
      // Handle cases where the input is not a valid URL
      return null;
    }
  }

  static buildYoutubeUrl(videoId: string): string {
    return `https://www.youtube.com/watch?v=${videoId}`;
  }

  static buildYoutubeEmbedUrl(videoId: string): string {
    return `https://www.youtube.com/embed/${videoId}`;
  }

  static buildYoutubeThumbnailUrl(videoId: string): string {
    return `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`;
  }

  static buildYoutubeIso8601UploadDate(uploadTimestampSeconds: number): string {
    const date = new Date(uploadTimestampSeconds * 1000);
    return date.toISOString();
  }
}